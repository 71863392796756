// workspaceService.ts

import { apiClient } from './api'; // Ensure you have this setup
import { Workspace, WorkspaceRole, WorkspaceWithRole } from '@/models/Workspace';


// Function to get all workspace roles
export const fetchWorkspaceRoles = async (): Promise<WorkspaceRole[]> => {
  const response = await apiClient.get('/workspace_roles');
  return response.data;
}


// Function to get all workspaces
export const fetchWorkspaces = async (): Promise<WorkspaceWithRole[]> => {
  const response = await apiClient.get('/workspaces');
  return response.data;
}

// Function to get a single workspace by ID
export const fetchWorkspaceById = async (workspaceId: string): Promise<Workspace> => {
  const response = await apiClient.get(`/workspaces/${workspaceId}`);
  return response.data;
}

// Function to create a new workspace
export const createWorkspace = async (data: Workspace): Promise<Workspace> => {
    const response = await apiClient.post('/workspaces', data);
    // Assuming the backend response includes the workspace_id in the format you mentioned
    return {
      ...data,
      id: response.data.workspace_id,
    };
  }

// Function to update a workspace
export const updateWorkspace = async (workspaceId: string, updatedWorkspaceData: Workspace): Promise<void> => {
  await apiClient.put(`/workspaces/${workspaceId}`, updatedWorkspaceData);
}

// Function to delete a workspace
export const deleteWorkspace = async (workspaceId: string): Promise<void> => {
  await apiClient.delete(`/workspaces/${workspaceId}`);
}


// ****************************** Association with users ******************************

// Function to associate a user with a workspace
export const createUserWorkspaceRole = async (userId: string, workspaceId: string, roleId: string): Promise<{ message: string }> => {
  const response = await apiClient.post('/user_workspace_roles/', {
    user_id: userId,
    workspace_id: workspaceId,
    workspace_role_id: roleId
  });
  return response.data;
};

// Function to update a user's role in a workspace
export const updateUserWorkspaceRole = async (
  userId: string,
  workspaceId: string,
  newRoleId: string
): Promise<{ message: string }> => {
  const response = await apiClient.put(`/user_workspace_roles/${userId}/${workspaceId}`, {workspace_role_id: newRoleId});
  return response.data;
};

// Function to remove a user from a workspace
export const deleteUserWorkspaceRole = async (
  userId: string,
  workspaceId: string
): Promise<{ message: string }> => {
  const response = await apiClient.delete(`/user_workspace_roles/${userId}/${workspaceId}`);
  return response.data;
};