// RagCollectionService.ts

import { User } from '@/models/User';
import { apiClient } from './api';
import { RagCollection, RagCollectionListing } from '@/models/RagCollection'; // Import your data model


// Get RAG collections
export const fetchRagCollections = async (workspaceId: string): Promise<RagCollection[]> => {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get('/rag_collections_all', { params });
  return response.data.rag_collections;
};

// Get all RAG collections
export const fetchAllRagCollections = async (workspaceId: string): Promise<RagCollectionListing[]> => {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get('/rag_collections_with_partial_server', { params });
  return response.data.rag_collections;
};

// Get a single RAG collection by ID
export const fetchRagCollectionById = async (id: string): Promise<RagCollection> => {
  const response = await apiClient.get(`/rag_collections/${id}`);
  return response.data.rag_collection;
};

// Create a new RAG collection
export const createRagCollection = async (data: RagCollection): Promise<string> => {
  const response = await apiClient.post('/rag_collections', data);
  return response.data.id;
};

// Edit a RAG collection
export const editRagCollection = async (id: string, data: RagCollection): Promise<void> => {
  await apiClient.put(`/rag_collections/${id}`, data);
};

// Delete a RAG collection
export const deleteRagCollection = async (id: string): Promise<void> => {
  await apiClient.delete(`/rag_collections/${id}`);
};


// ************************************ Association with users ************************************

// Get users associated to a RAG collection
export const fetchRagCollectionUsers = async (id: string): Promise<User[]> => {
  const response = await apiClient.get(`/rag-collections/${id}/users`);
  return response.data.users;
};

// Update the users associated with a RAG collection
export const updateRagCollectionUsers = async (id: string, userIDs: string[]): Promise<void> => {
  await apiClient.put(`/rag-collections/${id}/users`, { user_ids: userIDs });
};