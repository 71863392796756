// doc-button.tsx
import { BookOpen } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useTranslation } from 'react-i18next';

const DOCS_URL = 'https://docs.gravia.io';

export const DocsButton = () => {
  const { t } = useTranslation();

  const handleDocsClick = () => {
    window.open(DOCS_URL, '_blank', 'noopener noreferrer');
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="ghost"
            size="icon"
            className="rounded-full"
            onClick={handleDocsClick}
          >
            <BookOpen className="h-[1.2rem] w-[1.2rem]" />
            <span className="sr-only">{t('header.documentation')}</span>
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t('header.viewDocumentation')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};