// src/store/slices/toastSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Toast {
    id: number;
    message: string;
    type: 'success' | 'error';
}

interface ToastState {
    toasts: Toast[];
}

const initialState: ToastState = {
    toasts: []
};

const toastSlice = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        addToast: (state, action: PayloadAction<Omit<Toast, 'id'>>) => {
            const newToast = {
                id: Date.now(), // simple unique ID generation
                ...action.payload
            };
            state.toasts.push(newToast);
        },
        removeToast: (state, action: PayloadAction<number>) => {
            state.toasts = state.toasts.filter(toast => toast.id !== action.payload);
        }
    }
});

export const { addToast, removeToast } = toastSlice.actions;
export default toastSlice.reducer;

export type { ToastState };