// main.tsx
//import { StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { PersistGate } from 'redux-persist/integration/react';
import './i18n';
import * as Sentry from "@sentry/react";

import App from './App';
import './index.css'

import { store, persistor } from './store/store';
import { ThemeProvider } from '@/components/theme-provider';


Sentry.init({
  dsn: "https://c2ed08a8d86e035e00f1ee8a19792c3a@o4508071673200640.ingest.de.sentry.io/4508071678771280",
  integrations: [
    Sentry.browserTracingIntegration(),
    //Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", "https://app.gravia.io"],
  // Session Replay
  //replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  //replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});


ReactDOM.createRoot(document.getElementById('root')!).render(
  //<StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
          <ThemeProvider defaultTheme="system" storageKey="vite-ui-theme">
            <App />
            </ThemeProvider>
          </DndProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  //</StrictMode>
);
