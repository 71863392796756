// User.ts

export const USER_ROLE_ADMIN = 'admin';
export const USER_ROLE_CREATOR = 'creator'
export const USER_ROLE_USER = 'user'

/**
 * Represents a user within the application.
 */
export interface User {
  id: string;
  email: string;
  password: string;
  last_name: string;
  first_name: string;
  job_title: string;
  user_role_id: string;
  language_preference: string;
  organization_id?: string;
}

export interface UserWithWorkspace {
  id: string;
  email: string;
  password: string;
  last_name: string;
  first_name: string;
  job_title: string;
  user_role_id: string;
  workspace_role_id: string;
}

export interface UserProfileEdit {
  first_name?: string;
  last_name?: string;
  job_title?: string;
  language_preference?: string
}

/**
 * Represents a UserRole within the application.
 */
export interface UserRole {
  id: string;
  name: string;
}

export interface UserOnboardingInfo {
  language_preference: string;
  first_name: string;
  last_name: string;
  email: string;
  job_title: string;
}

export interface OnboardingData {
  onboarding_token: string;
  password: string;
  language_preference: string;
  first_name: string;
  last_name: string;
  email: string;
  job_title: string;
  terms_accepted: boolean;
}