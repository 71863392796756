// MainLayout.tsx
import React, { Suspense, lazy } from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import AppToaster from './components/AppToaster';
import { useAppSelector } from '@/store/hooks';

const OnboardingGuide = lazy(() => import('./components/OnboardingGuide'));

const MainLayout: React.FC = () => {
  const selectedWorkspaceId = useAppSelector((state) => state.workspace.selectedWorkspaceId);

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Header />
      <main className="flex flex-1 flex-col bg-muted/50 gap-4 md:gap-8">
        {selectedWorkspaceId ? <Outlet /> :
          <Suspense>
            <OnboardingGuide />
          </Suspense>
        }
      </main>
      <AppToaster />
    </div>
  );
};

export default MainLayout;