// src/store/store.ts
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';

import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import menuReducer from './slices/menuSlice';
import authReducer from './slices/authSlice';
import workspaceReducer from './slices/workspaceSlice';
import toastReducer from './slices/toastSlice';
import genModelReducer from './slices/genModelSlice';
import collectionReducer from './slices/collectionSlice';
import chatbotConfigReducer from './slices/chatbotConfigSlice';
import flowConfigReducer from './slices/flowConfigSlice';

const rootReducer = combineReducers({
  menu: menuReducer,
  auth: authReducer,
  workspace: workspaceReducer,
  toast: toastReducer,
  genModels: genModelReducer,
  collection: collectionReducer,
  chatbotConfig: chatbotConfigReducer,
  flowConfig: flowConfigReducer,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['auth', 'workspace'], // Reducer to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;