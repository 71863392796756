// utils/hooks/useWorkspaceRole.ts

import { useAppSelector } from '../../store/hooks';

export function useWorkspaceRole() {
    const workspaceRoleId = useAppSelector(state => state.workspace.selectedWorkspaceRoleId);

    const roles = {
        isWorkspaceAdmin: workspaceRoleId === 'admin',
        isWorkspaceCreator: workspaceRoleId === 'creator',
        isWorkspaceUser: workspaceRoleId === 'user'
    };

    return roles;
}