// AuthenticationRoutes.tsx
import { Suspense, lazy } from 'react';

import MinimalLayout from '@/components/layout/MinimalLayout/MinimalLayout';

const LoginPage = lazy(() => import('../pages/Authentication/Login'));
const LogoutPage = lazy(() => import('../pages/Authentication/Logout'));
const RequestPasswordResetPage = lazy(() => import('../pages/Authentication/RequestPasswordReset'));
const ResetPasswordPage = lazy(() => import('../pages/Authentication/ResetPassword'));
const OnboardingPage = lazy(() => import('../pages/Authentication/Onboarding'));

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
          path: 'login',
          element: <Suspense> <LoginPage /> </Suspense>
        },
        {
            path: 'logout',
            element: <Suspense> <LogoutPage /> </Suspense>
        },
        {
          path: 'request-password-reset',
          element: <Suspense> <RequestPasswordResetPage /> </Suspense>
        },
        {
          path: 'reset-password/:token',
          element: <Suspense> <ResetPasswordPage /> </Suspense>
        },
        {
          path: 'onboarding/:token',
          element: <Suspense> <OnboardingPage /> </Suspense>
        },
      ]
};

export default AuthenticationRoutes;