// WorkspaceSelection.tsx
import React, { useState, useEffect, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  DropdownMenu, DropdownMenuTrigger, DropdownMenuContent, DropdownMenuItem, DropdownMenuSeparator, DropdownMenuSub,
  DropdownMenuSubTrigger, DropdownMenuSubContent, DropdownMenuPortal,
  DropdownMenuLabel
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button"
import { Plus, Settings } from 'lucide-react';
import { useTranslation } from 'react-i18next';

import { fetchWorkspaces, createWorkspace, deleteWorkspace, updateWorkspace } from '../../../../services/workspaceService';
import WorkspaceModal from './Workspace/WorkspaceModal';
import { Workspace } from '../../../../models/Workspace';

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { addToast } from '../../../../store/slices/toastSlice';
import { setSelectedWorkspace, setWorkspaces } from '../../../../store/slices/workspaceSlice';
import { useRole } from '../../../../utils/hooks/useRole';
import { useWorkspaceRole } from '../../../../utils/hooks/useWorkspaceRole';
import MixpanelTracker from '@/utils/mixpanel';

const WorkspaceInviteModal = lazy(() => import('./Workspace/WorkspaceInviteModal'));
const WorkspaceMembersModal = lazy(() => import('./Workspace/WorkspaceMembersModal'));
const DeletionConfirmation = lazy(() => import('@/components/custom/DeletionConfirmation'));

const WorkspaceSelection: React.FC = () => {
  const { t } = useTranslation();
  const workspaces = useAppSelector((state) => state.workspace.workspaces);
  const [modalType, setModalType] = useState<'create' | 'edit' | ''>('');
  const [workspaceToEdit, setWorkspaceToEdit] = useState<Workspace | undefined>(undefined);
  const selectedWorkspaceId = useAppSelector((state) => state.workspace.selectedWorkspaceId);
  const [openDeletionConfirmation, setOpenDeletionConfirmation] = useState(false);
  const [openShareModal, setOpenShareModal] = useState(false);
  const [openMembersModal, setOpenMembersModal] = useState(false);
  const { isAdmin, isCreator } = useRole();
  const { isWorkspaceAdmin } = useWorkspaceRole();
  const [, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    loadWorkspaces();
  }, []);

  useEffect(() => {
    if (workspaces.length > 0) {
      if (!selectedWorkspaceId) {
        dispatch(setSelectedWorkspace({ workspaceId: workspaces[0].id, roleId: workspaces[0].workspace_role_id }));
      } else {
        const selectedWorkspace = workspaces.find(ws => ws.id === selectedWorkspaceId);
        if (selectedWorkspace) {
          dispatch(setSelectedWorkspace({ workspaceId: selectedWorkspace.id, roleId: selectedWorkspace.workspace_role_id }));
        } else {
          dispatch(setSelectedWorkspace({ workspaceId: null, roleId: null }));
        }
      }
    }
  }, [workspaces, selectedWorkspaceId, dispatch]);

  const loadWorkspaces = async () => {
    try {
      const workspaces = await fetchWorkspaces();
      dispatch(setWorkspaces(workspaces));

      if (workspaces.length === 0) {
        dispatch(setSelectedWorkspace({ workspaceId: null, roleId: null }));
      }
    } catch {
      dispatch(addToast({ message: t('workspaceSelection.loadError'), type: 'error' }));
    }
  };

  const handleOpenShareModal = () => {
    setOpenShareModal(true);
    handleMenuClose();
    MixpanelTracker.track("Workspace - Share Click");
  };

  const handleCloseShareModal = () => {
    setOpenShareModal(false);
  };

  const handleOpenMembersModal = () => {
    setOpenMembersModal(true);
    handleMenuClose();
    MixpanelTracker.track("Workspace - Members Click");
  };

  const handleCloseMembersModal = () => {
    setOpenMembersModal(false);
  };

  const handleWorkspaceChange = (workspaceId: string) => {
    MixpanelTracker.track("Workspace - Selected workspace");

    if (workspaceId === 'new') {
      setModalType('create');
      setWorkspaceToEdit(undefined);
    } else {
      const selectedWorkspace = workspaces.find(ws => ws.id === workspaceId);
      if (selectedWorkspace) {
        dispatch(setSelectedWorkspace({
          workspaceId: selectedWorkspace.id,
          roleId: selectedWorkspace.workspace_role_id
        }));
        navigate('/');
      }
    }
  };

  const handleSaveWorkspace = async (workspace: Workspace) => {
    MixpanelTracker.track("Workspace - Save");
    if (modalType === 'edit' && workspace.id) {
      try {
        await updateWorkspace(workspace.id, { name: workspace.name });
        dispatch(addToast({ message: t('workspaceSelection.editSuccess'), type: 'success' }));
        await loadWorkspaces();
      }
      catch {
        dispatch(addToast({ message: t('workspaceSelection.editError'), type: 'error' }));
        await loadWorkspaces();
        setModalType('');
        setWorkspaceToEdit(undefined);
      }
    } else if (modalType === 'create') {
      try {
        const newWorkspace = await createWorkspace({ name: workspace.name });
        await loadWorkspaces(); // Refresh the complete list
        const updatedWorkspaces = await fetchWorkspaces();
        const createdWorkspace = updatedWorkspaces.find(ws => ws.id === newWorkspace.id);
        if (createdWorkspace) {
          dispatch(setSelectedWorkspace({
            workspaceId: createdWorkspace.id,
            roleId: createdWorkspace.workspace_role_id
          }));
        }
        dispatch(addToast({ message: t('workspaceSelection.createSuccess'), type: 'success' }));
        setModalType('');
        setWorkspaceToEdit(undefined);
      }
      catch {
        dispatch(addToast({ message: t('workspaceSelection.createError'), type: 'error' }));
      }
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleConfigureClick = () => {
    MixpanelTracker.track("Workspace - Edit");

    const workspace = workspaces.find(ws => ws.id === selectedWorkspaceId);
    if (workspace) {
      setModalType('edit');
      setWorkspaceToEdit(workspace);
    }
    handleMenuClose();
  };

  const handleDeleteWorkspace = async () => {
    if (selectedWorkspaceId) {
      MixpanelTracker.track("Workspace - Delete confirmed");
  
      try {
        await deleteWorkspace(selectedWorkspaceId);
        dispatch(addToast({ message: t('workspaceSelection.deleteSuccess'), type: 'success' }));
      }
      catch {
        dispatch(addToast({ message: t('workspaceSelection.deleteError'), type: 'error' }));
      }
      finally {
        const updatedWorkspaces = await fetchWorkspaces();
        dispatch(setWorkspaces(updatedWorkspaces));
  
        if (updatedWorkspaces.length > 0) {
          // If there are still workspaces left, select the first one
          dispatch(setSelectedWorkspace({
            workspaceId: updatedWorkspaces[0].id,
            roleId: updatedWorkspaces[0].workspace_role_id
          }));
        } else {
          dispatch(setSelectedWorkspace({ workspaceId: null, roleId: null }));
        }
        
        navigate('/');
        handleMenuClose();
        setOpenDeletionConfirmation(false);
      }
    }
  };

  return (
    <>
      <div className="ml-auto flex-1 sm:flex-initial">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant='outline'>
              {workspaces && workspaces.length > 0
                ? (workspaces.find(ws => ws.id === selectedWorkspaceId)?.name || t('workspaceSelection.selectWorkspace'))
                : '...'
              }
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {(isAdmin || isWorkspaceAdmin) && selectedWorkspaceId && (
              <>
                <DropdownMenuSub>
                  <DropdownMenuSubTrigger>
                    <Settings className="mr-2 h-4 w-4" />
                    {t('common.settings')}
                  </DropdownMenuSubTrigger>
                  <DropdownMenuPortal>
                    <DropdownMenuSubContent>
                      <DropdownMenuItem onSelect={handleConfigureClick}>{t('common.edit')}</DropdownMenuItem>
                      <DropdownMenuItem onSelect={handleOpenMembersModal}>{t('workspaceSelection.members')}</DropdownMenuItem>
                      <DropdownMenuItem onSelect={handleOpenShareModal}>{t('common.share')}</DropdownMenuItem>
                      <DropdownMenuItem onSelect={() => setOpenDeletionConfirmation(true)}>{t('common.delete')}</DropdownMenuItem>
                    </DropdownMenuSubContent>
                  </DropdownMenuPortal>
                </DropdownMenuSub>
              </>
            )}
            {(isAdmin || isCreator) && (
              <>
                <DropdownMenuItem onSelect={() => handleWorkspaceChange('new')}>
                  <Plus className="mr-2 h-4 w-4" />
                  {t('workspaceSelection.createNew')}
                </DropdownMenuItem>
                <DropdownMenuSeparator />
              </>
            )}

            <DropdownMenuLabel>{t('workspaceSelection.workspacesLabel')}</DropdownMenuLabel>
            {workspaces && workspaces.length > 0 ? (
              workspaces.map(workspace => (
                <DropdownMenuItem key={workspace.id} onSelect={() => handleWorkspaceChange(workspace.id)}>
                  {workspace.name}
                </DropdownMenuItem>
              ))
            ) : (
              <DropdownMenuItem disabled>{t('workspaceSelection.noWorkspaces')}</DropdownMenuItem>
            )}

          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      {(modalType === 'create' || (modalType === 'edit' && workspaceToEdit)) && (
        <Suspense>
          <WorkspaceModal
            open={modalType === 'create' || modalType === 'edit'}
            onClose={() => {
              setModalType('');
              setWorkspaceToEdit(undefined);
            }}
            onSave={handleSaveWorkspace}
            workspace={workspaceToEdit}
          />
        </Suspense>
      )}

      {(isAdmin || isCreator) && (
        <>
          <Suspense>
            {openShareModal && selectedWorkspaceId && (
              <WorkspaceInviteModal
                open={openShareModal}
                onClose={handleCloseShareModal}
                workspaceId={selectedWorkspaceId}
              />
            )}
          </Suspense>

          <Suspense>
            {openMembersModal && selectedWorkspaceId && (
              <WorkspaceMembersModal
                open={openMembersModal}
                onClose={handleCloseMembersModal}
                workspaceId={selectedWorkspaceId}
              />
            )}
          </Suspense>

          <Suspense>
            {openDeletionConfirmation && (
              <DeletionConfirmation
                open={openDeletionConfirmation}
                onClose={() => setOpenDeletionConfirmation(false)}
                onConfirm={handleDeleteWorkspace}
                title={t('workspaceSelection.deleteConfirmTitle')}
                contentText={t('workspaceSelection.deleteConfirmContent')}
              />
            )}
          </Suspense>
        </>
      )}
    </>
  );
};

export default WorkspaceSelection;