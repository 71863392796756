// src/store/slices/authSlice.ts
import i18n from '@/i18n';

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '@/models/User';
import { fetchUserMySelf } from '@/services/userService';


interface AuthState {
  isAuthenticated: boolean;
  user: User | null
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null
};

export const fetchUserDetails = createAsyncThunk(
  'auth/fetchUserDetails',
  async (_, { rejectWithValue }) => {
    try {
      const user = await fetchUserMySelf();
      return user;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // not used normally
    setCredentials: (
      state: AuthState,
      action: PayloadAction<{ accessToken: string; refreshToken: string; user: User }>
    ) => {
      const { user } = action.payload;
      state.isAuthenticated = true;
      state.user = user;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.user = null;
    },
    updateLanguagePreference: (state, action: PayloadAction<string>) => {
      if (state.user) {
        state.user.language_preference = action.payload;
        i18n.changeLanguage(action.payload);
      }
    },
    
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserDetails.fulfilled, (state, action: PayloadAction<User>) => {
        state.isAuthenticated = true;
        state.user = action.payload;
        i18n.changeLanguage(action.payload.language_preference || 'en');
      })
      
      // Consider handling pending, and rejected states as well
  },
});


export const { setCredentials, logout, updateLanguagePreference } = authSlice.actions;
export default authSlice.reducer;
export type { AuthState };