// src/services/templateService.ts
import { ChatbotType } from '@/models/Chatbot';

// Import all template configurations
import simpleChatbotConfig from '@/utils/flow_templates/chatflows/simple-chatbot.json';
import qaChatbotConfig from '@/utils/flow_templates/chatflows/qa-chatbot.json';
import figmaDev from '@/utils/flow_templates/chatflows/figma-dev.json';
import notionChatbotConfig from '@/utils/flow_templates/chatflows//notion-chatbot.json';
import agent from '@/utils/flow_templates/chatflows/agent.json';

export interface TemplateConfig {
  id: string;
  name: string;
  description: string;
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  configuration: Record<string, any>;
}

const templates: TemplateConfig[] = [
  { ...simpleChatbotConfig },
  { ...qaChatbotConfig },
  { ...figmaDev},
  { ...notionChatbotConfig},
  { ...agent}
];

export const getTemplateConfig = (templateId: string, type: ChatbotType): TemplateConfig | undefined => {
  return templates.find(template => template.id === templateId && template.type === type);
};

export const getAllTemplates = (type: ChatbotType): TemplateConfig[] => {
  return templates.filter(template => template.type === type);
};