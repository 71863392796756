// feedback-button.tsx
import { MessageSquarePlus } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip';
import { useTranslation } from 'react-i18next';

const FEEDBACK_URL = 'https://docs.google.com/forms/d/e/1FAIpQLSc8Xfu58bU0oCtosXdXW8p8QThO9mwFdg8VGXxAr_7Yy-pRfw/viewform?usp=sf_link';

export const FeedbackButton = () => {
  const { t } = useTranslation();

  const handleFeedbackClick = () => {
    window.open(FEEDBACK_URL, '_blank', 'noopener noreferrer');
  };

  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger asChild>
          <Button
            variant="outline"
            size="icon"
            className="rounded-full hover:bg-primary/90 hover:text-primary-foreground"
            onClick={handleFeedbackClick}
          >
            <MessageSquarePlus className="h-[1.2rem] w-[1.2rem]" />
          </Button>
        </TooltipTrigger>
        <TooltipContent>
          <p>{t('header.leaveFeedback')}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};