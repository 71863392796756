import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Menu } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from '@/components/ui/sheet';

import { NavLinks } from './NavLinks';
import WorkspaceSelection from './WorkspaceSelection';
import { UserMenu } from '../../shared_components/UserMenu';
import { ModeToggle } from '../../shared_components/mode-toggle';

import logo from '@/assets/gravia.svg';
import { DocsButton } from '../../shared_components/doc-button';
import { FeedbackButton } from '../../shared_components/feedback-button';

const Header: React.FC = () => {
  const [isSheetOpen, setIsSheetOpen] = useState(false);
  const { t } = useTranslation();

  const handleSheetClose = () => {
    setIsSheetOpen(false);
  };

  return (
    <header className="sticky top-0 flex h-16 items-center gap-4 border-b bg-background px-4 md:px-6 z-50">
      <Sheet open={isSheetOpen} onOpenChange={setIsSheetOpen}>
        <SheetTrigger asChild>
          <Button variant="outline" size="icon" className="shrink-0 md:hidden">
            <Menu className="h-5 w-5" />
            <span className="sr-only">{t('header.toggleNavigationMenu')}</span>
          </Button>
        </SheetTrigger>
        <SheetContent side="left">
          <SheetHeader>
            <SheetTitle>{t('header.menu')}</SheetTitle>
          </SheetHeader>
          <NavLinks isMobile onItemClick={handleSheetClose} />
        </SheetContent>
      </Sheet>

      <Link to="/" className="flex items-center gap-2 text-lg font-semibold">
        <img src={logo} alt='Gravia logo' className="h-6 dark:invert" />
        <span className="sr-only">Gravia</span>
      </Link>

      <WorkspaceSelection />

      <div className="flex flex-1 justify-center">
        <nav className="hidden flex-col gap-6 text-lg font-medium md:flex md:flex-row md:items-center md:gap-5 md:text-sm lg:gap-6">
          <NavLinks />
        </nav>
      </div>
      <div className="flex items-center gap-4 md:gap-2 lg:gap-4">
        <ModeToggle />
        <FeedbackButton />
        <DocsButton />
        <UserMenu />
      </div>
    </header>
  );
};

export default Header;