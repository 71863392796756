// utils/hooks/useRole.ts

import { useAppSelector } from '../../store/hooks';

export type UserRole = 'admin' | 'creator' | 'user';

export interface Roles {
  isAdmin: boolean;
  isCreator: boolean;
  isUser: boolean;
  userRole?: string;
}

export function useRole(): Roles {
  const user = useAppSelector(state => state.auth.user);

  const roles: Roles = {
    isAdmin: user?.user_role_id === 'admin',
    isCreator: user?.user_role_id === 'creator',
    isUser: user?.user_role_id === 'user',

    userRole: user?.user_role_id
  };

  return roles;
}