// authService.ts
import { OnboardingData, UserOnboardingInfo } from '@/models/User';
import { apiClient, tokenRefreshClient } from './api';

interface LoginResponse {
  access_token: string;
  refresh_token: string;
  expires: number;
  token_type: string;
}

export const login = async (email: string, password: string): Promise<LoginResponse> => {
  const response = await apiClient.post<LoginResponse>('/token', { email, password });
  return response.data;
};

export const refreshToken = async (refreshToken: string): Promise<LoginResponse> => {
  const response = await tokenRefreshClient.post<LoginResponse>('/token/refresh', { refresh_token: refreshToken });
  return response.data;
}

export const requestPasswordReset = async (email: string): Promise<void> => {
  const response = await apiClient.post('/auth/request-password-reset', { email });
  if (response.status !== 200) {
    throw new Error(response.data.message || 'Failed to request password reset');
  }
};

export const setPassword = async (token: string, password: string): Promise<{ access_token: string; refresh_token: string; expires: string; token_type: string }> => {
  const response = await apiClient.post('/auth/set-password', { token, password });
  return response.data;
};

export const getUserOnboardingInfo = async (token: string): Promise<UserOnboardingInfo> => {
  const response = await apiClient.get(`/users/onboarding/${token}`);
  return response.data;
};

export const completeOnboarding = async (onboardingData: OnboardingData): Promise<void> => {
  const response = await apiClient.post('/users/onboarding/complete', onboardingData);
  return response.data;
};