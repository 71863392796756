// App.tsx
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import './App.css'
import Routes from './routes/router';
import { fetchUserDetails, logout as storeLogout } from './store/slices/authSlice';
import { useAppDispatch, useAppSelector } from './store/hooks';
import MixpanelTracker from '@/utils/mixpanel';

const App = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);
  
  // Language
  useEffect(() => {
    if (user) {
      i18n.changeLanguage(user.language_preference);
    }
  }, [user, i18n]);

  // Token refresh
  useEffect(() => {
    const handleTokenRefresh = () => {
      dispatch(fetchUserDetails());
    };
    const handleTokenRefreshFailed = () => {
      dispatch(storeLogout());
    };

    window.addEventListener('tokenRefreshed', handleTokenRefresh);
    window.addEventListener('tokenRefreshFailed', handleTokenRefreshFailed);

    return () => {
      window.removeEventListener('tokenRefreshed', handleTokenRefresh);
      window.removeEventListener('tokenRefreshFailed', handleTokenRefreshFailed);
    };
  }, [dispatch]);

  // Mixpanel identity setup
  useEffect(() => {
    if (user) {
      // Identify user in Mixpanel with limited properties
      MixpanelTracker.identify(user.id, user.user_role_id, user.organization_id!);
    }
  }, [user]);

  return <Routes />;
};

export default App;