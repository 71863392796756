// store/slices/genModelSlice.ts
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAllModelsWithPartialServer } from '@/services/modelService';
import { ModelWithPartialServer } from '@/models/Model';

interface ModelState {
  models: ModelWithPartialServer[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: ModelState = {
  models: [],
  status: 'idle',
  error: null,
};

export const fetchModels = createAsyncThunk(
  'genModels/fetchModels',
  async (workspaceId: string) => {
    const response = await fetchAllModelsWithPartialServer(workspaceId);
    return response;
  }
);

const genModelSlice = createSlice({
  name: 'genModels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModels.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchModels.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.models = action.payload;
      })
      .addCase(fetchModels.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message || 'Failed to fetch gen models';
      });
  },
});

export default genModelSlice.reducer;
export type { ModelState };