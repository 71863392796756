// routes.index.tsx
import { useRoutes } from 'react-router-dom';
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import AdminRoutes from './AdminRoutes';
import ForbiddenPage from '@/pages/ForbiddenPage';

const Routes = () => {
  return useRoutes([MainRoutes,
    AuthenticationRoutes,
    AdminRoutes,
    {
      path: '/forbidden',
      element: <ForbiddenPage />
    }
  ]);
};

export default Routes;