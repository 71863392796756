// AppToaster.tsx
import { useEffect } from 'react';

import { Toaster } from '@/components/ui/toaster';
import { useToast } from '@/components/ui/use-toast';

import { removeToast } from '@/store/slices/toastSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';


const AppToaster = () => {
    const toasts = useAppSelector((state) => state.toast.toasts);
    const dispatch = useAppDispatch();
    const { toast } = useToast()

    useEffect(() => {
        toasts.forEach(function (atoast) {
            let variant: "default" | "destructive" | null | undefined = null
            switch(atoast.type) {
                case 'success':  {
                    variant='default';
                    break;
                }
                case 'error': {
                    variant='destructive';
                    break;
                }
                default: {
                    variant='default';
                    break;
                }
            }

            toast({
                description: atoast.message,
                variant: variant
            })
            dispatch(removeToast(atoast.id))
        });

    }, [toasts]);

    return (
        <>
            <Toaster />
        </>
    );
};

export default AppToaster;