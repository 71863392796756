// src/pages/ForbiddenPage.tsx

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/ui/button';
import MixpanelTracker from '@/utils/mixpanel';
import { useNavigate } from 'react-router-dom';

const ForbiddenPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Track page view
    MixpanelTracker.trackPageView({ page: "Forbidden Page" });
  }, []);
  
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-4xl font-bold mb-4">{t('forbiddenPage.title')}</h1>
      <p className="mb-8">{t('forbiddenPage.message')}</p>
      <Button onClick={() => navigate('/')}>{t('forbiddenPage.goHome')}</Button>
    </div>
  );
};

export default ForbiddenPage;