// UserMenu.tsx
import React, { useState, lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CircleUser } from 'lucide-react';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { useRole } from '@/utils/hooks/useRole';
import MixpanelTracker from '@/utils/mixpanel';

const ProfileEditModal = lazy(() => import('./components/ProfileEditModal'));

export const UserMenu: React.FC = () => {
  const navigate = useNavigate();
  const [isProfileEditOpen, setIsProfileEditOpen] = useState(false);
  const { isAdmin } = useRole();
  const { t } = useTranslation();

  const handleAdmin = () => {
    navigate('/admin');
  };

  const handleLogout = () => {
    navigate('/logout');
  };

  const handleProfileEdit = () => {
    MixpanelTracker.track("User Profile - Modal Display");
    setIsProfileEditOpen(true);
  };

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="secondary" size="icon" className="rounded-full">
            <CircleUser className="h-5 w-5" />
            <span className="sr-only">{t('userMenu.toggleMenu')}</span>
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end">

          {isAdmin &&
            <>
              <DropdownMenuItem onSelect={handleAdmin}>{t('userMenu.administration')}</DropdownMenuItem>
              <DropdownMenuSeparator />
            </>
          }

          <DropdownMenuLabel>{t('userMenu.myAccount')}</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={handleProfileEdit}>{t('userMenu.myProfile')}</DropdownMenuItem>
          <DropdownMenuSeparator />
          <DropdownMenuItem onSelect={handleLogout}>{t('userMenu.logout')}</DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>

      <Suspense>
        {isProfileEditOpen && (
          <ProfileEditModal
            open={isProfileEditOpen}
            onClose={() => setIsProfileEditOpen(false)}
          />
        )}
      </Suspense>
    </>
  );
};