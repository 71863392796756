// src/store/slices/workspaceSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WorkspaceWithRole } from '@/models/Workspace';

interface WorkspaceState {
  selectedWorkspaceId: string | null;
  selectedWorkspaceRoleId: string | null;
  workspaces: WorkspaceWithRole[];
}

const initialState: WorkspaceState = {
  selectedWorkspaceId: null,
  selectedWorkspaceRoleId: null,
  workspaces: []
};

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState,
  reducers: {
    setSelectedWorkspace(state, action: PayloadAction<{ workspaceId: string | null, roleId: string | null }>) {
      state.selectedWorkspaceId = action.payload.workspaceId;
      state.selectedWorkspaceRoleId = action.payload.roleId;
    },
    setWorkspaces(state, action: PayloadAction<WorkspaceWithRole[]>) {
      state.workspaces = action.payload;
    }
  },
});

export const { setSelectedWorkspace, setWorkspaces } = workspaceSlice.actions;

export default workspaceSlice.reducer;
export type { WorkspaceState };