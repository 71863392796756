// WorkspaceModal.tsx
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogClose
} from '@/components/ui/dialog';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Workspace } from '@/models/Workspace';
import { useTranslation } from 'react-i18next';

interface WorkspaceModalProps {
  open: boolean;
  onClose: () => void;
  onSave: (workspace: Workspace) => Promise<void>;
  workspace?: Workspace;
}

const WorkspaceModal: React.FC<WorkspaceModalProps> = ({ open, onClose, onSave, workspace }) => {
  const [workspaceName, setWorkspaceName] = useState(workspace?.name || '');
  const { t } = useTranslation();

  useEffect(() => {
    if (open) {
      setWorkspaceName(workspace?.name || '');
    }
  }, [open, workspace]);

  const handleSave = async () => {
    if (workspaceName) {
      await onSave({ ...workspace, name: workspaceName });
    }
    onClose();
  };

  return (
    <Dialog open={open} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {workspace?.id ? t('workspace.modal.editTitle') : t('workspace.modal.createTitle')}
          </DialogTitle>
          <DialogDescription>
            {workspace?.id 
              ? t('workspace.modal.editDescription')
              : t('workspace.modal.createDescription')}
          </DialogDescription>
        </DialogHeader>
        <div className="space-y-4">
          <Input
            autoFocus
            id="name"
            placeholder={t('workspace.modal.namePlaceholder')}
            value={workspaceName}
            onChange={e => setWorkspaceName(e.target.value)}
            autoComplete="off"
          />
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t('common.cancel')}</Button>
          </DialogClose>
          <Button onClick={handleSave}>
            {workspace?.id ? t('common.update') : t('common.create')}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default WorkspaceModal;