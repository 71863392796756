// AdminRoutes.tsx

import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';

import RoleProtectedRoute from '@/context/RoleProtectedRoute';
import AdminLayout from '@/components/layout/AdminLayout/AdminLayout';
import { USER_ROLE_ADMIN } from '@/models/User';

const UsersPage = lazy(() => import('../pages/Users/UsersPage'));

const AdminRoutes = {
    path: '/admin',
    element: (
        <RoleProtectedRoute allowedRoles={[USER_ROLE_ADMIN]}>
            <AdminLayout />
        </RoleProtectedRoute>
    ),
    children: [
        {
            path: '',
            element: <Navigate to="users" />
        },
        {
            path: 'users',
            element: (
                <Suspense>
                    <UsersPage />
                </Suspense>
            )
        }
    ]
};

export default AdminRoutes;