// modelService.ts

import { apiClient } from './api';
import { Model, ModelWithPartialServer, ModelType, MODEL_TYPE_LLM } from '@/models/Model';


export async function fetchAllModelTypes(): Promise<ModelType[]> {
  const response = await apiClient.get<{ data: ModelType[] }>('/models/types');
  return response.data.data;
}

export async function fetchAllModels(workspaceId: string): Promise<Model[]> {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get<{ models: Model[] }>('/models', { params });
  return response.data.models;
}

export async function fetchAllModelsWithPartialServer(workspaceId: string): Promise<ModelWithPartialServer[]> {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get<{ models: ModelWithPartialServer[] }>('/models_with_partial_server', { params });
  return response.data.models;
}

export async function fetchAllLlmModelsWithPartialServer(workspaceId: string): Promise<ModelWithPartialServer[]> {
  const params = { workspace_id: workspaceId, model_type: MODEL_TYPE_LLM }
  const response = await apiClient.get<{ models: ModelWithPartialServer[] }>('/models_with_partial_server', { params });
  return response.data.models;
}

export async function fetchAllEmbeddingsModels(workspaceId: string): Promise<Model[]> {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get<{ models: Model[] }>('/models_embeddings', { params });
  return response.data.models;
}



export async function fetchModelById(genModelId: string): Promise<Model> {
  const response = await apiClient.get<{ model: Model }>(`/models/${genModelId}`);
  return response.data.model;
}

export async function createModel(genModel: Model): Promise<string> {
  const response = await apiClient.post<{ model_id: string }>('/models', genModel);
  return response.data.model_id;
}

export async function editModel(genModelId: string, genModel: Model): Promise<void> {
  await apiClient.put(`/models/${genModelId}`, genModel);
}

export async function deleteModel(genModelId: string): Promise<void> {
  await apiClient.delete(`/models/${genModelId}`);
}