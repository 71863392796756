// toolService.ts
import { apiClient } from './api';
import { Tool, ToolType } from '@/models/Tool';

export async function fetchAllTools(workspaceId: string): Promise<Tool[]> {
  const response = await apiClient.get<{ data: Tool[] }>(`/tools`, {
    params: {
      workspace_id: workspaceId
    }
  });
  return response.data.data;
}

export async function fetchAllToolTypes(): Promise<ToolType[]> {
  const response = await apiClient.get<{ data: ToolType[] }>('/tools/types');
  return response.data.data;
}

export async function fetchToolById(toolId: string): Promise<Tool> {
  const response = await apiClient.get<{ data: Tool }>(`/tools/${toolId}`);
  return response.data.data;
}

export async function createTool(tool: Tool): Promise<string> {
  const response = await apiClient.post<{ tool_id: string }>('/tools', tool);
  return response.data.tool_id;
}

export async function editTool(toolId: string, tool: Tool): Promise<void> {
  await apiClient.put(`/tools/${toolId}`, tool);
}

export async function deleteTool(toolId: string): Promise<void> {
  await apiClient.delete(`/tools/${toolId}`);
}