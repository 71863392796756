// ragConnectorService.ts

import { apiClient } from './api';
import { RagConnector, RagConnectorConfig } from '@/models/RagConnector';
import { CollectionMetadataDTO, CollectionPreview } from '@/models/RagCollection';

// Get all supported connectors
export const fetchSupportedConnectors = async (): Promise<RagConnector[]> => {
  const response = await apiClient.get('/supported_connectors');
  return response.data.connectors;
};

// Get all connectors
export const fetchAllConnectors = async (workspaceId: string): Promise<RagConnectorConfig[]> => {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get('/connectors', { params });
  return response.data.connectors;
};

// Get a single connector by ID
export const fetchConnectorById = async (id: string): Promise<RagConnectorConfig> => {
  const response = await apiClient.get(`/connectors/${id}`);
  return response.data.connector;
};

// Get the list of collections within the vector database
export const fetchConnectorCollectionsInDbById = async (id: string): Promise<string[]> => {
  const response = await apiClient.get(`/connectors/${id}/collections`);
  return response.data.collections;
};

// Retrieve metadata of a specific collection within the vector database
export const fetchCollectionMetadata = async (connectorId: string, collectionName: string): Promise<CollectionMetadataDTO> => {
  const response = await apiClient.get(`/connectors/${connectorId}/collections/${collectionName}/metadata`);
  return response.data;
};

// Retrieve preview data
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchCollectionPreview = async (connectorId: string, collectionName: string, limit: number = 10, offset: number = 0): Promise<CollectionPreview> => {
  const response = await apiClient.get(`/connectors/${connectorId}/collections/${collectionName}/preview`, {
      params: { limit, offset },
  });
  return response.data;
};

// Create a new connector
export const createConnector = async (data: RagConnectorConfig): Promise<string> => {
  const response = await apiClient.post('/connectors', data);
  return response.data.id;
};

// Edit a connector
export const editConnector = async (id: string, data: RagConnectorConfig): Promise<void> => {
  await apiClient.put(`/connectors/${id}`, data);
};

// Delete a connector
export const deleteConnector = async (id: string): Promise<void> => {
  await apiClient.delete(`/connectors/${id}`);
};