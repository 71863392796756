// src/store/slices/collectionSlice.ts -> used for collection creation/edition page

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { createRagCollection, editRagCollection, fetchRagCollectionById } from '@/services/ragCollectionService';
import { fetchAllConnectors, fetchCollectionPreview} from '@/services/ragConnectorService';
import { fetchAllEmbeddingsModels } from '@/services/modelService';

import { CollectionPreview, RagCollection } from '@/models/RagCollection';
import { RagConnectorConfig } from '@/models/RagConnector';
import { Model } from '@/models/Model';

interface CollectionState {
    collectionData: Partial<RagCollection>;
    embeddingsModels: Model[];
    connectors: RagConnectorConfig[];
    currentStep: number;
    loading: boolean;
    isConfigurationStepValid: boolean; // Mandatory fields are filled or not
    previewData: CollectionPreview | null;
    error: string | null;
}

const initialState: CollectionState = {
    collectionData: {},
    embeddingsModels: [],
    connectors: [],
    currentStep: 0,
    loading: false,
    isConfigurationStepValid: false,
    previewData: null,
    error: null,
};

export const fetchInitialData = createAsyncThunk(
    'collection/fetchInitialData',
    async (workspaceId: string, { rejectWithValue }) => {
        try {
            const [embeddingsModels, connectors] = await Promise.all([
                fetchAllEmbeddingsModels(workspaceId),
                fetchAllConnectors(workspaceId)
            ]);
            return { embeddingsModels, connectors };
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchCollectionData = createAsyncThunk(
    'collection/fetchCollectionData',
    async (collectionId: string, { rejectWithValue }) => {
        try {
            const collectionData = await fetchRagCollectionById(collectionId);
            return collectionData;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const saveCollection = createAsyncThunk(
    'collection/saveCollection',
    async (collectionData: Partial<RagCollection>, { rejectWithValue }) => {
        try {
            if (collectionData.id) {
                await editRagCollection(collectionData.id, collectionData as RagCollection);
            } else {
                await createRagCollection(collectionData as RagCollection);
            }
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

export const fetchPreviewData = createAsyncThunk(
    'collection/fetchPreviewData',
    async ({ connectorId, collectionName }: { connectorId: string, collectionName: string }, { rejectWithValue }) => {
        try {
            const previewData = await fetchCollectionPreview(connectorId, collectionName);
            return previewData;
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const collectionSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: {
        setCollectionData: (state, action: PayloadAction<Partial<RagCollection>>) => {
            state.collectionData = { ...state.collectionData, ...action.payload };
        },
        nextStep: (state) => {
            state.currentStep += 1;
        },
        prevStep: (state) => {
            state.currentStep -= 1;
        },
        resetSteps: (state) => {
            state.currentStep = 0;
        },
        resetCollectionState: (state) => {
            state.collectionData = {};
            state.embeddingsModels = [];
            state.connectors = [];
            state.currentStep = 0;
            state.loading = false;
            state.isConfigurationStepValid = false;
            state.previewData = null;
            state.error = null;
        },
        resetPreviewState: (state) => {
            state.previewData = null;
        },
        setIsConfigurationStepValid: (state, action: PayloadAction<boolean>) => {
            state.isConfigurationStepValid = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchInitialData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchInitialData.fulfilled, (state, action) => {
                state.loading = false;
                state.embeddingsModels = action.payload.embeddingsModels;
                state.connectors = action.payload.connectors;
            })
            .addCase(fetchInitialData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })
            
            .addCase(fetchCollectionData.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchCollectionData.fulfilled, (state, action) => {
                state.loading = false;
                state.collectionData = action.payload;
            })
            .addCase(fetchCollectionData.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(saveCollection.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(saveCollection.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(saveCollection.rejected, (state, action) => {
                state.loading = false;
                state.error = action.payload as string;
            })

            .addCase(fetchPreviewData.pending, (state) => {
                //state.loading = true;
                state.error = null;
            })
            .addCase(fetchPreviewData.fulfilled, (state, action) => {
                //state.loading = false;
                //console.log(action.payload);
                state.previewData = action.payload;
                //console.log(state.previewData);
            })
            .addCase(fetchPreviewData.rejected, (state, action) => {
                //state.loading = false;
                state.error = action.payload as string;
            });
    },
});

export const { setCollectionData, nextStep, prevStep, setIsConfigurationStepValid, resetSteps, resetPreviewState, resetCollectionState } = collectionSlice.actions;

export default collectionSlice.reducer;
export type { CollectionState };