// NavLinks.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Aperture } from 'lucide-react';
import { useTranslation } from 'react-i18next';

interface NavLinksProps {
  isMobile?: boolean;
}

export const NavLinks: React.FC<NavLinksProps> = (/*{ isMobile = false }*/) => {
  const { t } = useTranslation();

  return (
    <>
      <NavLink to="/studio" className='flex items-center gap-2 px-3 py-2 rounded-full transition-colors
       bg-ghost text-muted-foreground hover:text-foreground'>
        <Aperture className="h-5 w-5" />
        <span>{t('admin.navLinks.backToApp')}</span>
      </NavLink>
    </>
  );
};