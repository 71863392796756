// NavLinks.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Brain, BookOpen, Aperture, BarChart } from 'lucide-react';
import { useWorkspaceRole } from '@/utils/hooks/useWorkspaceRole';
import { useTranslation } from 'react-i18next';

interface NavLinksProps {
  isMobile?: boolean;
  onItemClick?: () => void;
}

export const NavLinks: React.FC<NavLinksProps> = ({ isMobile = false, onItemClick }) => {
  const workspaceRole = useWorkspaceRole();

  const baseClasses = "flex items-center gap-2 px-3 py-2 rounded-full transition-colors";
  const activeClasses = "bg-primary text-white dark:bg-primary-dark dark:text-primary-foreground";
  const inactiveClasses = "bg-ghost text-muted-foreground hover:text-foreground";

  const isAdminOrCreator = workspaceRole.isWorkspaceAdmin || workspaceRole.isWorkspaceCreator;
  const isAdminOrCreatorOrUser = workspaceRole.isWorkspaceAdmin || workspaceRole.isWorkspaceCreator || workspaceRole.isWorkspaceUser;
  const { t } = useTranslation();

  const handleClick = () => {
    if (isMobile && onItemClick) {
      onItemClick();
    }
  };

  return (
    <>
      {isAdminOrCreator && (
        <>
          <NavLink to="/providers" className={({ isActive }) => `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`} onClick={handleClick}>
            <Brain className="h-5 w-5" />
            <span>{t('menu.providers')}</span>
          </NavLink>
          <NavLink to="/knowledges" className={({ isActive }) => `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`} onClick={handleClick}>
            <BookOpen className="h-5 w-5" />
            <span>{t('menu.knowledges')}</span>
          </NavLink>
          <NavLink to="/studio" className={({ isActive }) => `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`} onClick={handleClick}>
            <Aperture className="h-5 w-5" />
            <span>{t('menu.studio')}</span>
          </NavLink>
        </>
      )}

      {isAdminOrCreatorOrUser && (
        <NavLink to="/analytics" className={({ isActive }) => `${baseClasses} ${isActive ? activeClasses : inactiveClasses}`} onClick={handleClick}>
          <BarChart className="h-5 w-5" />
          <span>{t('menu.analytics')}</span>
        </NavLink>
      )}
      
    </>
  );
};