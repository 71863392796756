// Model.ts

export const MODEL_TYPE_LLM = 'llm';
export const MODEL_TYPE_EMBEDDINGS = 'text-embeddings';
export const MODEL_TYPE_RERANK = 'rerank';


export interface Model {
    id: string;
    name: string;
    model_type: string;
    description: string;
    provider_id: string;
    provider_model: string;
    workspace_id: string;
}

export interface ModelWithPartialServer {
  id: string;
  name: string;
  model_type: string;
  description: string;
  provider_id: string;
  provider_model: string;
  workspace_id: string;

  // Inference server info
  provider_type: string;
  provider_name: string;
}


export interface ProviderAvailableModels {
  id: string;
  created: string;
  owned_by: string;
  object: string;
  model_type: string;
}

export interface ModelType {
  id: string;
  name: string;
}