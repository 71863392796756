// src/store/slices/chatbotConfigSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Chatbot, ChatbotSimpleCreateEdit } from '@/models/Chatbot';

interface ChatbotConfigState {
  chatbot: Chatbot | null;
  config: ChatbotSimpleCreateEdit | null;
  isLoading: boolean;
  error: string | null;
}

const initialState: ChatbotConfigState = {
  chatbot: null,
  config: null,
  isLoading: false,
  error: null,
};

const chatbotConfigSlice = createSlice({
  name: 'chatbotConfig',
  initialState,
  reducers: {
    setChatbot: (state, action: PayloadAction<Chatbot>) => {
      state.chatbot = action.payload;
    },
    setConfig: (state, action: PayloadAction<ChatbotSimpleCreateEdit>) => {
      state.config = action.payload;
    },
    updateConfig: (state, action: PayloadAction<Partial<ChatbotSimpleCreateEdit>>) => {
      if (state.config) {
        state.config = { ...state.config, ...action.payload };
      }
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    reinitialize: (state) => {
      state.chatbot = null;
      state.config = null;
      state.error = null;
    },
  },
});

export const { setChatbot, setConfig, updateConfig, setLoading, setError, reinitialize } = chatbotConfigSlice.actions;
export default chatbotConfigSlice.reducer;
export type { ChatbotConfigState };