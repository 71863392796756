// AdminLayout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import Header from './components/Header';
import AppToaster from './components/AppToaster';
import { AdminMenu } from './components/AdminMenu';
import { useTranslation } from 'react-i18next';

const AdminLayout: React.FC = () => {
  const { t } = useTranslation();

  return (
    <div className="flex min-h-screen w-full flex-col">
      <Header />
      <main className="flex min-h-[calc(100vh_-_theme(spacing.16))] flex-1 flex-col gap-4 bg-muted/40 p-4 md:gap-8 md:p-10">
        <div className="mx-auto grid w-full max-w-6xl gap-2">
          <h1 className="text-3xl font-semibold">{t('admin.layout.title')}</h1>
        </div>

        <div className="mx-auto grid w-full max-w-6xl items-start gap-6 md:grid-cols-[180px_1fr] lg:grid-cols-[250px_1fr]">
          <AdminMenu />

          <div className="grid gap-6">
            <Outlet />
          </div>
        </div>

      </main>
      <AppToaster />
    </div>
  );
};

export default AdminLayout;