// src/store/slices/menuSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

interface MenuState {
  openItem: string[];
  defaultId: string;
  openComponent: string;
  drawerOpen: boolean;
  componentDrawerOpen: boolean;
}

const initialState: MenuState = {
  openItem: ['dashboard'],
  defaultId: 'dashboard',
  openComponent: 'buttons',
  drawerOpen: false,
  componentDrawerOpen: true,
};

export const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setActiveItem(state, action: PayloadAction<string[]>) {
      state.openItem = action.payload;
    },
    setActiveComponent(state, action: PayloadAction<string>) {
      state.openComponent = action.payload;
    },
    setDrawerOpen(state, action: PayloadAction<boolean>) {
      state.drawerOpen = action.payload;
    },
    setComponentDrawerOpen(state, action: PayloadAction<boolean>) {
      state.componentDrawerOpen = action.payload;
    },
  },
});

// Actions generated from the slice
export const {
  setActiveItem,
  setActiveComponent,
  setDrawerOpen,
  setComponentDrawerOpen,
} = menuSlice.actions;

// A selector
export const selectMenu = (state: RootState) => state.menu;

// The reducer
export default menuSlice.reducer;
export type { MenuState };