// MainRoutes.tsx
import { Suspense, lazy } from 'react';
import MainLayout from '@/components/layout/MainLayout/MainLayout';
import RoleProtectedRoute from '@/context/RoleProtectedRoute';
import { USER_ROLE_ADMIN, USER_ROLE_CREATOR, USER_ROLE_USER } from '@/models/User';

const HomePage = lazy(() => import('../pages/Index'));

const ProvidersPage = lazy(() => import('../pages/Providers/ProvidersPage'));

const KnowledgesPage = lazy(() => import('../pages/Knowledges/KnowledgePage/KnowledgesPage'));
const CollectionCreationPage = lazy(() => import('../pages/Knowledges/CollectionsManagement/CollectionCreationPage'));
const CollectionEditionPage = lazy(() => import('../pages/Knowledges/CollectionsManagement/CollectionEditionPage'));

const StudioPage = lazy(() => import('../pages/Studio/StudioPage'));
const SimpleChatbotPage = lazy(() => import('../pages/Studio/SimpleChatbot/SimpleChatbotPage'));
const ChatflowChatbotPage = lazy(() => import('../pages/Studio/ChatflowChatbot/ChatflowChatbotPage'));
const WorkflowChatbotPage = lazy(() => import('../pages/Studio/WorkflowChatbot/WorkflowChatbotPage'));

const AnalyticsPage = lazy(() => import('../pages/Analytics/AnalyticsPage'));


const MainRoutes = {
    path: '/',
    element: (
        <RoleProtectedRoute allowedRoles={[USER_ROLE_ADMIN, USER_ROLE_CREATOR, USER_ROLE_USER]}>
            <MainLayout />
        </RoleProtectedRoute>
    ), children: [
        {
            path: '',
            element: (
                <Suspense>
                    <HomePage />
                </Suspense>
            )
        },
        {
            path: 'providers',
            element: (
                <Suspense>
                    <ProvidersPage />
                </Suspense>
            )
        },
        {
            path: 'knowledges',
            element: (
                <Suspense>
                    <KnowledgesPage />
                </Suspense>
            )
        },
        {
            path: 'knowledges/collections/create',
            element: (
                <Suspense>
                    <CollectionCreationPage />
                </Suspense>
            )
        },
        {
            path: 'knowledges/collections/edit/:id',
            element: (
                <Suspense>
                    <CollectionEditionPage />
                </Suspense>
            )
        },
        {
            path: 'studio',
            element: (
                <Suspense>
                    <StudioPage />
                </Suspense>
            )
        },
        {
            path: 'studio/simple/:chatbotId',
            element: (
                <Suspense>
                    <SimpleChatbotPage />
                </Suspense>
            )
        },
        {
            path: 'studio/chatflow/:chatbotId',
            element: (
                <Suspense>
                    <ChatflowChatbotPage />
                </Suspense>
            )
        },
        {
            path: 'studio/workflow/:chatbotId',
            element: (
                <Suspense>
                    <WorkflowChatbotPage />
                </Suspense>
            )
        },
        {
            path: 'analytics',
            element: (
                <Suspense>
                    <AnalyticsPage />
                </Suspense>
            )
        }
    ]
};

export default MainRoutes;