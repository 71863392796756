// src/components/RoleProtectedRoute.tsx

import { ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { useRole, UserRole } from '@/utils/hooks/useRole';

interface RoleProtectedRouteProps {
  children: ReactNode;
  allowedRoles: UserRole[];
}

const RoleProtectedRoute = ({ children, allowedRoles }: RoleProtectedRouteProps) => {
  const { userRole } = useRole();

  if(!userRole) {
    return <Navigate to="/login" replace />;
  }

  const hasPermission = userRole && allowedRoles.includes(userRole as UserRole);
  
  if (!hasPermission) {
    // User doesn't have the required role, redirect to a forbidden page or dashboard
    return <Navigate to="/forbidden" replace />;
  }

  return <>{children}</>;
};

export default RoleProtectedRoute;