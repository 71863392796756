// userService.ts

import { apiClient } from './api';
import { User, UserProfileEdit, UserRole, UserWithWorkspace } from '@/models/User';


// Function to get all users roles
export const fetchUserRoles = async (): Promise<UserRole[]> => {
  const response = await apiClient.get('/users/roles');
  return response.data.user_roles;
}


// Function to get all users
export const fetchUsers = async (): Promise<User[]> => {
  const response = await apiClient.get('/users');
  return response.data.users;
}

// Function to get all users having access to a specific workspace
export const fetchUsersByWorkspace = async (workspaceId: string): Promise<UserWithWorkspace[]> => {
  const response = await apiClient.get(`/users/workspace/${workspaceId}`);
  return response.data.users;
}

// Function to get a single user by ID
export const fetchUserById = async (userId: string): Promise<User> => {
  const response = await apiClient.get(`/users/${userId}`);
  return response.data.user;
}

// Function to get the user that is connected
export const fetchUserMySelf = async (): Promise<User> => {
  const response = await apiClient.get('/users/details/me');
  return response.data.user;
}

// Function to create a new user
export const createUser = async (data: User): Promise<string> => {
  const response = await apiClient.post('/users', data);
  return response.data.user_id;
}

// Function to update a user
export const updateUser = async (userId: string, updatedUserData: User): Promise<void> => {
  await apiClient.put(`/users/${userId}`, updatedUserData);
}

// Function to update the current user's profile
export const updateUserProfile = async (updatedProfileData: UserProfileEdit): Promise<string> => {
  const response = await apiClient.patch('/users/profile/me', updatedProfileData);
  return response.data.message;
};

// Function to delete a user
export const deleteUser = async (userId: string): Promise<void> => {
  await apiClient.delete(`/users/${userId}`);
}