// flowConfigSlice.ts
import { CoordinateExtent } from '@xyflow/react';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { Chatbot, ChatbotChatflow, ChatbotWorkflow, CustomNode, CustomEdge, CustomNodeData, CustomGraphData } from '@/models/Chatbot';
import { fetchChatbotById, fetchChatbotChatflowConfig, fetchChatbotWorkflowConfig, editChatflowChatbotConfig, editWorkflowChatbotConfig, publishChatbot } from '@/services/chatbotService';
import { MODEL_TYPE_EMBEDDINGS, MODEL_TYPE_LLM, MODEL_TYPE_RERANK, ModelWithPartialServer } from '@/models/Model';
import { fetchAllModelsWithPartialServer } from '@/services/modelService';
import { RagCollection } from '@/models/RagCollection';
import { RagConnectorConfig } from '@/models/RagConnector';
import { fetchRagCollections } from '@/services/ragCollectionService';
import { NodeExecutionResult } from '@/services/chatService';
import { getTemplateConfig } from '@/services/templateService';
import { fetchAllConnectors } from '@/services/ragConnectorService';

import { RootState } from '../store';
import { Tool } from '@/models/Tool';
import { fetchAllTools } from '@/services/toolService';

interface FlowConfigState {
  chatbot: Chatbot | null;
  selectedNodeId: string | null;
  config: ChatbotChatflow | ChatbotWorkflow | null;
  isLoading: boolean;
  isLoadingConfig: boolean;
  showTemplateModal: boolean;
  error: string | null;

  llm_models: ModelWithPartialServer[];
  rerank_models: ModelWithPartialServer[];
  embeddings_models: ModelWithPartialServer[];
  rag_connectors: RagConnectorConfig[];
  knowledge_collections: RagCollection[];
  tools: Tool[];

  nodeExecutions: NodeExecutionResult[] | null;
  selectedExecutionNodeId: string | null;
}

const initialState: FlowConfigState = {
  chatbot: null,
  selectedNodeId: null,
  config: null,
  isLoading: false,
  isLoadingConfig: false,
  showTemplateModal: false,
  error: null,

  llm_models: [],
  rerank_models: [],
  embeddings_models: [],
  rag_connectors: [],
  knowledge_collections: [],
  tools: [],
  
  nodeExecutions: null,
  selectedExecutionNodeId: null,
};

// Util function to create empty config
const createEmptyConfig = (chatbotId: string, workspaceId: string): ChatbotChatflow | ChatbotWorkflow => ({
  chatbot_id: chatbotId,
  configuration: {
    nodes: [],
    edges: []
  },
  is_published: false,
  workspace_id: workspaceId
});

// Fetch models
export const fetchModels = createAsyncThunk(
  'flowConfig/fetchModels',
  async (workspaceId: string) => {
    const models = await fetchAllModelsWithPartialServer(workspaceId);

    const llm_models = models.filter((model) => model.model_type === MODEL_TYPE_LLM);
    const rerank_models = models.filter((model) => model.model_type === MODEL_TYPE_RERANK);
    const embeddings_models = models.filter((model) => model.model_type === MODEL_TYPE_EMBEDDINGS);

    return {
      llm_models,
      rerank_models,
      embeddings_models,
    };
  }
);

// Fetch rag (knowledge) collections
export const fetchKnowledges = createAsyncThunk(
  'flowConfig/fetchKnowledges',
  async (workspaceId: string) => {
    const collections = await fetchRagCollections(workspaceId);
    return { collections };
  }
);

// Fetch rag connectors
export const fetchRagConnectors = createAsyncThunk(
  'flowConfig/fetchRagConnectors',
  async (workspaceId: string) => {
    const connectors = await fetchAllConnectors(workspaceId);
    return { connectors };
  }
);

// Fetch tools
export const fetchTools = createAsyncThunk(
  'flowConfig/fetchTools',
  async (workspaceId: string) => {
    const tools = await fetchAllTools(workspaceId);
    return { tools };
  }
);

// Fetch flow configuration (chatflow or workflow)
export const fetchFlowConfig = createAsyncThunk(
  'flowConfig/fetchConfig',
  async (chatbotId: string) => {
    const chatbot = await fetchChatbotById(chatbotId);
    let config;
    if (chatbot.type === 'chatflow') {
      config = await fetchChatbotChatflowConfig(chatbotId);
    } else if (chatbot.type === 'workflow') {
      config = await fetchChatbotWorkflowConfig(chatbotId);
    } else {
      throw new Error('Invalid chatbot type');
    }
    return {
      chatbot,
      config: config || createEmptyConfig(chatbotId, chatbot.workspace_id)
    };
  }
);

// Save flow configuration (chatflow or workflow)
export const saveFlowConfig = createAsyncThunk(
  'flowConfig/saveConfig',
  async (_, { getState }) => {
    const state = getState() as { flowConfig: FlowConfigState };
    const { chatbot, config } = state.flowConfig;
    if (!chatbot || !config) {
      throw new Error('Chatbot or config is null');
    }
    if (chatbot.type === 'chatflow') {
      await editChatflowChatbotConfig(chatbot.id, config as ChatbotChatflow);
    } else if (chatbot.type === 'workflow') {
      await editWorkflowChatbotConfig(chatbot.id, config as ChatbotWorkflow);
    }
    return config;
  }
);

// Publish flow (chatflow or workflow)
export const publishFlow = createAsyncThunk(
  'flowConfig/publish',
  async (_, { getState }) => {
    const state = getState() as { flowConfig: FlowConfigState };
    const { chatbot } = state.flowConfig;
    if (!chatbot) {
      throw new Error('Chatbot is null');
    }
    await publishChatbot(chatbot.id);
    return chatbot.id;
  }
);

// Apply template
export const applyTemplate = createAsyncThunk<
  ChatbotChatflow | ChatbotWorkflow,
  { templateId: string; type: 'chatflow' | 'workflow' },
  { state: RootState }
>(
  'flowConfig/applyTemplate',
  async ({ templateId, type }, { getState }) => {
    const templateConfig = getTemplateConfig(templateId, type);
    if (!templateConfig) {
      throw new Error('Template not found');
    }

    const state = getState();
    const currentConfig = state.flowConfig.config;
    const chatbot = state.flowConfig.chatbot;

    if (!currentConfig || !chatbot) {
      throw new Error('Current configuration or chatbot not found');
    }

    // Ensure the template configuration matches CustomGraphData
    const newConfiguration: CustomGraphData = {
      nodes: templateConfig.configuration.nodes as CustomNode[],
      edges: templateConfig.configuration.edges as CustomEdge[]
    };

    // Create the updated config object
    const updatedConfig: ChatbotChatflow | ChatbotWorkflow = {
      ...currentConfig,
      configuration: newConfiguration,
    };

    return updatedConfig;
  }
);

// STORE SLICE
const flowConfigSlice = createSlice({
  name: 'flowConfig',
  initialState,
  reducers: {
    setShowTemplateModal: (state, action: PayloadAction<boolean>) => {
      state.showTemplateModal = action.payload;
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    updateNodeFields: (state, action: PayloadAction<{ nodeId: string; fields: Record<string, any> }>) => {
      const { nodeId, fields } = action.payload;
      if (state.config) {
        const node = state.config.configuration.nodes.find((node) => node.id === nodeId);
        if (node) {
          node.data.fields = { ...node.data.fields, ...fields };
        }
      }
    },
    updateNodeData: (state, action: PayloadAction<{ nodeId: string; updates: Partial<CustomNodeData> }>) => {
      //console.log("+++updateNodeData");
      const { nodeId, updates } = action.payload;
      //console.log(nodeId);
      if (state.config) {
        const node = state.config.configuration.nodes.find((node) => node.id === nodeId);
        //console.log(node?.data);
        if (node) {
          node.data = { ...node.data, ...updates };
          //console.log(node.data);
        }
      }
      //console.log("+++++++++++++++++")
    },
    updateNodeParent: (state, action: PayloadAction<{ nodeId: string; updates: { parentId: string | undefined, extent: "parent" | undefined | CoordinateExtent } }>) => {
      const { nodeId, updates } = action.payload;
      //console.log("+++updateNodeAttributes");
      if (state.config) {
        const node = state.config.configuration.nodes.find((node) => node.id === nodeId);
        if (node) {
          node.parentId = updates.parentId;
          node.extent = updates.extent;
        }
      }
    },
    addNode: (state, action: PayloadAction<CustomNode>) => {
      //console.log("+++addNode");
      if (state.config) {
        state.config.configuration.nodes.push(action.payload);
        //console.log(state.config.configuration.nodes);
      }
      //console.log("+++++++++++++++++++");
    },
    deleteNode: (state, action: PayloadAction<string>) => {
      if (state.config) {
        state.config.configuration.nodes = state.config.configuration.nodes.filter(
          (node) => node.id !== action.payload
        );
        state.config.configuration.edges = state.config.configuration.edges.filter(
          (edge) => edge.source !== action.payload && edge.target !== action.payload
        );
      }
    },
    updateNodePosition: (state, action: PayloadAction<{ nodeId: string; position: { x: number; y: number } }>) => {
      if (state.config) {
        const node = state.config.configuration.nodes.find((node) => node.id === action.payload.nodeId);
        if (node) {
          node.position = action.payload.position;
        }
      }
    },
    updateNodeSize: (state, action: PayloadAction<{ nodeId: string; style: { width: number; height: number } }>) => {
      if (state.config) {
        const node = state.config.configuration.nodes.find((node) => node.id === action.payload.nodeId);
        if (node) {
          node.width = action.payload.style.width;
          node.height = action.payload.style.height;
        }
      }
    },
    addEdge: (state, action: PayloadAction<CustomEdge>) => {
      if (state.config) {
        state.config.configuration.edges.push(action.payload);
      }
    },
    updateEdge: (state, action: PayloadAction<CustomEdge>) => {
      if (state.config) {
        const index = state.config.configuration.edges.findIndex(
          (edge) => edge.id === action.payload.id
        );
        if (index !== -1) {
          state.config.configuration.edges[index] = action.payload;
        }
      }
    },
    updateEdges: (state, action: PayloadAction<CustomEdge[]>) => {
      if (state.config) {
        state.config.configuration.edges = action.payload;
      }
    },
    deleteEdge: (state, action: PayloadAction<string>) => {
      if (state.config) {
        state.config.configuration.edges = state.config.configuration.edges.filter(
          (edge) => edge.id !== action.payload
        );
      }
    },
    selectNode: (state, action: PayloadAction<string | null>) => {
      state.selectedNodeId = action.payload;
      state.selectedExecutionNodeId = null;
    },
    unselectNode: (state) => {
      state.selectedNodeId = null;
      state.selectedExecutionNodeId = null;
    },
    setNodeExecutions: (state, action: PayloadAction<NodeExecutionResult[]>) => {
      state.nodeExecutions = action.payload;
    },
    selectExecutionNode: (state, action: PayloadAction<string>) => {
      state.selectedExecutionNodeId = action.payload;
    },
    unselectExecutionNode: (state) => {
      state.selectedExecutionNodeId = null;
    },
    reinitialize: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModels.fulfilled, (state, action) => {
        state.isLoading = false;
        state.llm_models = action.payload.llm_models;
        state.rerank_models = action.payload.rerank_models;
        state.embeddings_models = action.payload.embeddings_models;
      })
      .addCase(fetchKnowledges.fulfilled, (state, action) => {
        state.isLoading = false;
        state.knowledge_collections = action.payload.collections;
      })
      .addCase(fetchRagConnectors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.rag_connectors = action.payload.connectors;
      })
      .addCase(fetchTools.fulfilled, (state, action) => {
        state.tools = action.payload.tools;
      })
      .addCase(fetchFlowConfig.pending, (state) => {
        state.isLoading = true;
        state.isLoadingConfig = true;
        state.chatbot = null;
        state.config = null;
        state.error = null;
      })
      .addCase(fetchFlowConfig.fulfilled, (state, action) => {
        //console.log("fetchFlowConfig");
        state.chatbot = action.payload.chatbot;
        state.config = action.payload.config;
        state.isLoading = false;
        state.isLoadingConfig = false;
        state.showTemplateModal = action.payload.config.configuration.nodes.length === 0;
        //console.log(state.config);
      })
      .addCase(fetchFlowConfig.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoadingConfig = false;
        state.chatbot = null;
        state.config = null;
        state.error = action.error.message || 'Failed to fetch flow configuration';
      })
      .addCase(applyTemplate.fulfilled, (state, action) => {
        state.config = action.payload;
        state.error = null;
      })
      .addCase(saveFlowConfig.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(saveFlowConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.config = action.payload;
      })
      .addCase(saveFlowConfig.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to save flow configuration';
      })
      .addCase(publishFlow.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(publishFlow.fulfilled, (state) => {
        state.isLoading = false;
        /*
        if (state.chatbot) {
          state.chatbot.is_published = true;
        }
          */
      })
      .addCase(publishFlow.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.error.message || 'Failed to publish flow';
      });
  },
});

export const {
  setShowTemplateModal,
  updateNodeFields,
  updateNodeData,
  updateNodeParent,
  addNode,
  deleteNode,
  updateNodePosition,
  updateNodeSize,
  addEdge,
  updateEdge,
  updateEdges,
  deleteEdge,
  selectNode,
  unselectNode,
  setNodeExecutions,
  selectExecutionNode,
  unselectExecutionNode,
  reinitialize
} = flowConfigSlice.actions;

export default flowConfigSlice.reducer;
export type { FlowConfigState };