// chatbotService.ts

import { Chatbot, ChatbotCreate, ChatbotEdit, ChatbotSimple, ChatbotSimpleCreateEdit, ChatbotChatflow, ChatbotChatflowCreateEdit, ChatbotWorkflow, ChatbotWorkflowCreateEdit } from "@/models/Chatbot";
import { apiClient } from "./api";

// *************************** Chatbots managemen(shared info) ***************************

// Fetch all chatbots
export const fetchChatbots = async (workspaceId: string): Promise<Chatbot[]> => {
  const params = { workspace_id: workspaceId}
  const response = await apiClient.get('/chatbots', { params });
  return response.data.chatbots;
};

// Fetch a single chatbot by ID
export const fetchChatbotById = async (chatbotId: string): Promise<Chatbot> => {
  const response = await apiClient.get(`/chatbots/${chatbotId}`);
  return response.data.chatbot;
};

// Create a new chatbot
export const createChatbot = async (chatbotData: ChatbotCreate): Promise<string> => {
  const response = await apiClient.post('/chatbots', chatbotData);
  return response.data.id;
};

// Publish a chatbot
export const publishChatbot = async (chatbotId: string): Promise<string> => {
  const response = await apiClient.post(`/chatbots/${chatbotId}/publish`);
  return response.data.message;
};

// Duplicate a chatbot
export const duplicateChatbot = async (chatbotId: string, newName: string): Promise<string> => {
  const response = await apiClient.post('/chatbots/duplicate', { chatbot_id: chatbotId, new_name: newName });
  return response.data.id;
};

// Edit an existing chatbot
export const editChatbot = async (chatbotId: string, chatbotData: ChatbotEdit): Promise<string> => {
  const response = await apiClient.put(`/chatbots/${chatbotId}`, chatbotData);
  return response.data.message;
};


// Delete a chatbot
export const deleteChatbot = async (chatbotId: string): Promise<string> => {
  const response = await apiClient.delete(`/delete_chatbot/${chatbotId}`);
  return response.data.message;
};


// *************************** Chatbots config management ***************************

// Fetch chatbot configuration (simple)
export const fetchChatbotSimpleConfig = async (chatbotId: string): Promise<ChatbotSimple> => {
  const response = await apiClient.get(`/chatbots/${chatbotId}/config`);
  return response.data.config;
};

// Fetch chatbot configuration (chatflow)
export const fetchChatbotChatflowConfig = async (chatbotId: string): Promise<ChatbotChatflow> => {
  const response = await apiClient.get(`/chatbots/${chatbotId}/config`);
  return response.data.config;
};

// Fetch chatbot configuration (workflow)
export const fetchChatbotWorkflowConfig = async (chatbotId: string): Promise<ChatbotWorkflow> => {
  const response = await apiClient.get(`/chatbots/${chatbotId}/config`);
  return response.data.config;
};

// Edit simple chatbot configuration
export const editSimpleChatbotConfig = async (chatbotId: string, config: ChatbotSimpleCreateEdit): Promise<string> => {
  const response = await apiClient.put(`/chatbots/${chatbotId}/simple/config`, config);
  return response.data.message;
};

// Edit chatflow chatbot configuration
export const editChatflowChatbotConfig = async (chatbotId: string, config: ChatbotChatflowCreateEdit): Promise<string> => {
  const response = await apiClient.put(`/chatbots/${chatbotId}/chatflow/config`, config);
  return response.data.message;
};

// Edit workflow chatbot configuration
export const editWorkflowChatbotConfig = async (chatbotId: string, config: ChatbotWorkflowCreateEdit): Promise<string> => {
  const response = await apiClient.put(`/chatbots/${chatbotId}/chatflow/config`, config);
  return response.data.message;
};