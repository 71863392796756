// utils/mixpanel.ts
import mixpanel from 'mixpanel-browser';

const config = {
  enableLogging: true // Control the activation or not of mixpanel logs
};


// Initialize Mixpanel with your project token
mixpanel.init('eee5de65b25b2ea39560b974a5e3ccf8', {
  debug: false, //process.env.NODE_ENV !== 'production',
  //track_pageview: 'full-url', // Track any URL changes in the path, query string, or hash
});

export const MixpanelTracker = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  identify: (userId: string, userRole: string, organizationId: string) => {
    if (!config.enableLogging) return;
    try {
      mixpanel.identify(userId);
      mixpanel.people.set({
        $user_id: userId,
        user_role: userRole,
        organization_id: organizationId
      });
    } catch (error) {
      console.error('Mixpanel identify error:', error);
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  track: (event_name: string, properties?: Record<string, any>) => {
    if (!config.enableLogging) return;
    try {
      mixpanel.track(event_name, properties);
    } catch (error) {
      console.error('Mixpanel identify error:', error);
    }
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trackPageView: (properties?: Record<string, any>) => {
    if (!config.enableLogging) return;
    try {
      mixpanel.track_pageview(properties);
    } catch (error) {
      console.error('Mixpanel identify error:', error);
    }
  },

  reset: () => {
    try {
      mixpanel.reset();
    } catch (error) {
      console.error('Mixpanel identify error:', error);
    }
  },
};

export default MixpanelTracker;