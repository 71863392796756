// AdminMenu.tsx
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const AdminMenu: React.FC = () => {
    const { t } = useTranslation();
    const activeClasses = "font-semibold text-primary";

    return (
        <nav className="grid gap-4 text-sm text-muted-foreground" x-chunk="dashboard-04-chunk-0">
            <NavLink to="/admin/users" className={({ isActive }) => `${isActive ? activeClasses : ''}`}>
                {t('admin.menu.users')}
            </NavLink>

            { /*}
            <NavLink to="/admin/organization" >
                {t('admin.menu.organization')}
            </NavLink>
            */}

        </nav>
    );
};